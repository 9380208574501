import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCountries(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/ethnic', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCountry(ctx, ethnicData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/ethnic/create', { ethnic: ethnicData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editCountry(ctx, { id, ethnic }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/ethnic/update/${id}`, { ethnic })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCountryState(ctx, { id, isActive }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/ethnic/setstatus', { id, is_active: isActive })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCountry(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/ethnic/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <country-list-add-new
          v-if="$can('create', 'Country')"
          :is-add-new-country-modal-show.sync="isAddNewCountryModalShow"
          @refetch-data="refetchData"
        />

        <country-list-edit
          v-if="$can('update', 'Country')"
          :is-edit-country-modal-show.sync="isEditCountryModalShow"
          :data.sync="selectedCountry"
          @refetch-data="refetchData"
        />

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="$can('create', 'Country')"
            variant="primary"
            @click="isAddNewCountryModalShow = true"
          >
            Add Record
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              debounce="500"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="status => status.value"
              class="country-filter-select"
              placeholder="Select Status"
              label="text"
            >
              <template #selected-option="{ text }">
                <span class="text-truncate overflow-hidden">
                  {{ text }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refCountryListTable"
      :items="fetchCountries"
      responsive
      :fields="tableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Status -->
      <template #cell(is_active)="data">
        <template v-if="data.value">
          <b-badge
            pill
            variant="light-success"
          >
            Active
          </b-badge>
        </template>
        <template v-else>
          <b-badge
            pill
            variant="light-secondary"
          >
            Inactive
          </b-badge>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="$can('update', 'Country')"
            :id="`country-row-${data.item._id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="openEditCountryModal(data.item)"
          />
          <b-tooltip
            v-if="$can('update', 'Country')"
            title="Edit Country"
            class="cursor-pointer"
            :target="`country-row-${data.item._id}-send-icon`"
            triggers="hover"
            placement="left"
          />

          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :disabled="!$can('delete', 'Country')"
              @click="deleteCountry(data)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCountry"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  // BMedia, BAvatar, BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
// import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import useCountryList from './useCountryList'
import CountryListAddNew from './CountryListAddNew.vue'
import CountryListEdit from './CountryListEdit.vue'

import countryStoreModule from '../countryStoreModule'

export default {
  components: {
    CountryListAddNew,
    CountryListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const COUNTRY_MASTER_STORE_MODULE_NAME = 'master-country'

    // Register module
    if (!store.hasModule(COUNTRY_MASTER_STORE_MODULE_NAME)) store.registerModule(COUNTRY_MASTER_STORE_MODULE_NAME, countryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COUNTRY_MASTER_STORE_MODULE_NAME)) store.unregisterModule(COUNTRY_MASTER_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { text: 'Active', value: true },
      { text: 'Inactive', value: false },
    ]

    const isAddNewCountryModalShow = ref(false)
    const isEditCountryModalShow = ref(false)
    const selectedCountry = ref(null)

    const openEditCountryModal = data => {
      selectedCountry.value = data
      isEditCountryModalShow.value = true
    }

    const {
      fetchCountries,
      refetchData,
      deleteCountry,
      sortBy,
      perPageOptions,
      isSortDirDesc,
      tableColumns,
      perPage,
      searchQuery,
      statusFilter,
      refCountryListTable,
      currentPage,
      totalCountry,
      dataMeta,
    } = useCountryList()

    return {
      fetchCountries,
      refetchData,
      deleteCountry,
      sortBy,
      perPage,
      searchQuery,
      perPageOptions,
      statusFilter,
      isSortDirDesc,
      tableColumns,
      refCountryListTable,
      totalCountry,
      currentPage,
      dataMeta,

      openEditCountryModal,
      isAddNewCountryModalShow,
      isEditCountryModalShow,
      selectedCountry,
      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.country-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <!-- Add new country modal -->
  <b-modal
    id="modal-new-country"
    :visible="isAddNewCountryModalShow"
    cancel-variant="outline-secondary"
    ok-title="Create"
    cancel-title="Close"
    centered
    title="Create New Country"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="nameState"
        label="Country"
        label-for="country"
        invalid-feedback="Country name is required"
      >
        <b-form-input
          id="country"
          v-model="countryName"
          type="text"
          :state="nameState"
          placeholder="Country Name"
          required
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-country-modal-show',
  },
  props: {
    isAddNewCountryModalShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const countryName = ref('')
    const nameState = ref(null)
    const form = ref(null)

    const toast = useToast()

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        // eslint-disable-next-line no-useless-return
        return
      }

      store.dispatch('master-country/addCountry', countryName.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-country-modal-show', false)

          toast({
            component: ToastificationContent,
            props: {
              title: 'Success creating new country',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new country',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmit()
    }

    const resetModal = () => {
      countryName.value = ''
      nameState.value = null
      emit('update:is-add-new-country-modal-show', false)
    }

    return {
      countryName,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,
    }
  },
}
</script>

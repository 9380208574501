<template>
  <!-- Add new country modal -->
  <b-modal
    id="modal-new-country"
    :visible="isEditCountryModalShow"
    cancel-variant="outline-secondary"
    ok-title="Save"
    cancel-title="Close"
    centered
    title="Edit Country"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        :state="nameState"
        label="Country"
        label-for="country"
        invalid-feedback="Country name is required"
      >
        <b-form-input
          id="country"
          v-model="countryName"
          type="text"
          :state="nameState"
          placeholder="Country Name"
          required
        />
      </b-form-group>
      <b-form-group
        label="Status"
        label-for="status"
      >
        <div>
          <b-form-checkbox
            :checked="countryState"
            class="custom-control-success"
            name="check-button"
            switch
            @change="updateState"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BFormCheckbox,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: ['isEditCountryModalShow', 'data'],
    event: ['update:is-edit-country-modal-show', 'update:data'],
  },
  props: {
    isEditCountryModalShow: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const countryName = ref('')
    const countryState = ref(false)
    const nameState = ref(null)
    const form = ref(null)

    const toast = useToast()

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    watch(() => props.isEditCountryModalShow, val => {
      if (val) {
        countryName.value = props.data.country
        countryState.value = props.data.is_active
      }
    })

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        // eslint-disable-next-line no-useless-return
        return
      }

      // EDIT ETHNIC NAME //
      // eslint-disable-next-line no-underscore-dangle
      store.dispatch('master-country/editCountry', { country: countryName.value, id: props.data._id })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-country-modal-show', false)

          toast({
            component: ToastificationContent,
            props: {
              title: 'Success editing a country',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error editing a country',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // UPDATE STATUS ACTIVE INACTIVE
    const updateState = () => {
      // eslint-disable-next-line no-underscore-dangle
      store.dispatch('master-country/updateCountryState', { id: props.data._id, isActive: !countryState.value })
        .then(() => {
          emit('refetch-data')
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating state',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (props.data.country !== countryName.value) {
        handleSubmit()
      } else {
        emit('update:is-edit-country-modal-show', false)
      }
    }

    const resetModal = () => {
      countryName.value = ''
      nameState.value = null
      emit('update:is-edit-country-modal-show', false)
      emit('update:data', null)
    }

    return {
      countryName,
      countryState,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,
      updateState,
    }
  },
}
</script>
